<template>
    <div style="max-width: 300px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="name" label="工艺名称">
                <el-input placeholder="请输入工艺名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { addAbility, editAbility } from '@/service/dictionary';
    export default {
        props: ['item'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入工艺名称', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    name:"",
                },
                checkList:[],
                selectList:[],
            }
        },
        watch:{
            item:{
                handler() {
                    this.ruleForm = this.item;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.item;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                if (this.ruleForm && this.ruleForm.id) {
                    editAbility(this.ruleForm).then(rst => {
                        this.hide();
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    addAbility(this.ruleForm).then(rst => {
                        this.hide();
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            hide() {
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    
</style>