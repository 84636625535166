<template>
    <div class="editorAttr" style="max-width: 450px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" label-width="110px" ref="formCheck" class="login-form">
            <el-form-item label="工艺名称">
                <div>{{ruleForm.ability_name}}</div>
            </el-form-item>
            <el-form-item prop="name" label="衡量指标名称">
                <el-input v-model="ruleForm.name" style="max-width:203px;" placeholder="请输入衡量指标名称"></el-input>
            </el-form-item>
            <el-form-item prop="type" label="指标类型">
                <el-select v-model="ruleForm.type" placeholder="请选择">
                    <el-option v-for="item in typeList" :key="item.value" 
                    :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="ruleForm.type==1 || ruleForm.type==2" label="指标类型选择">
                <div class="content">
                    <div v-if="ruleForm.options && ruleForm.options.length>0" class="list">
                        <div v-for="(item,idx) in ruleForm.options" :key="idx" class="flex">
                            <div>
                                <el-input style="width:140px;margin-right:5px;" size="small" v-model="item.v" placeholder="请输入内容"></el-input>
                            </div>
                            <div style="width:80px;text-align:center;">
                                <el-button @click="delClass(idx)" type="text" size="mini">删除</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="add flex" style="padding-bottom:10px;">
                        <div><el-input style="width:140px;margin-right:5px;" size="small" v-model="createdName" placeholder="请输入内容"></el-input></div>
                        <div><el-button size="mini" @click="addClass()" type="primary">确认</el-button></div>
                    </div>
                </div>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:20px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>

    </div>
</template>
<script>
    import { addQuota,editQuota } from '@/service/dictionary';
    
    export default {
        components:{},
        props: ['item','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入衡量指标名称', trigger: 'blur' }
                    ],
                    type: [
                        { required: true, message: '请选择指标类型', trigger: 'blur' }
                    ],
                },
                typeList:[
                    {id:1,name:"单选"},
                    {id:2,name:"多选"},
                    {id:3,name:"文本"},
                    {id:4,name:"整数"},
                    {id:5,name:"小数"},
                    {id:6,name:"百分比"},
                ],
                ruleForm:{
                    ability_name:"",
                    name:"",
                    type:"",
                    options: [],
                },
                checked:"",
                createdName:"",
                colorValue:"",
            }
        },
        watch:{
            item:{
                handler() {
                    this.ruleForm = this.item;
                },
                deep: true,
            },
        },
        created() {
            this.ruleForm = this.item;
        },
        mounted() {},
        methods: {
            addClass() {
                if (this.createdName) {
                    this.ruleForm.options.push({
                        k:(new Date()).getTime(),
                        v:this.createdName,
                    });
                    this.createdName = "";
                }
            },
            delClass(index) {
                this.ruleForm.options.splice(index,1);
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                if (this.popTitle == "新增工艺能力指标") {
                    this.$delete(this.ruleForm,"ability_name");
                    addQuota(this.ruleForm).then(rst => {
                        this.hide();
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    editQuota(this.ruleForm).then(rst => {
                        this.hide();
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            hide() {
                this.$emit("hide");
            },
        }
    }
</script>
<style scoped>
    .header>div {
        font-weight: 600;
        color: #000;
        line-height:30px;
    }
    .list>div {
        margin-bottom: 10px;
    }
</style>