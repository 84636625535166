<template>
    <div style="height:100%">
        <div class="page-header">工艺能力字典</div>
        <div class="block">
            <div class="header">工艺名称</div>
            <div style="padding:20px;" class="select_list flex">
                <div class="select_item" v-for="(item,idx) in abilityList" @click="tab(item,idx)" :class="{'active':abilityIndex===idx}" :key="idx">
                    <div>{{item.name}}</div>
                    <i v-if="abilityIndex!==idx" @click.stop="del_ability(item)" class="iconfont icon-Group"></i>
                </div>
                <div @click="add_ability()" class="add_item">添加工艺能力</div>
            </div>
        </div>
        <div class="block">
            <div class="header flex">
                <div class="flex-1">
                    <span v-if="abilityList && abilityList.length>0">{{abilityList[abilityIndex].name}}</span>
                    <span @click="edit_ability()" style="padding:4px 10px;font-size:14px;font-weight:400;color:#409EFF;margin-left:20px;cursor:pointer;">修改</span>
                </div>
                <div>
                    <el-button @click="add_quota()" size="mini" style="width: 80px;" type="primary">添加指标</el-button>
                </div>
            </div>
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="quotaList" style="width: 100%;">
                    <el-table-column prop="id" label="ID" width="120">
                    </el-table-column>
                    <el-table-column prop="name" label="衡量指标">
                    </el-table-column>
                    <el-table-column prop="type" label="指标类型">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type==1">单选</div>
                            <div v-if="scope.row.type==2">多选</div>
                            <div v-if="scope.row.type==3">文本</div>
                            <div v-if="scope.row.type==4">整数</div>
                            <div v-if="scope.row.type==5">小数</div>
                            <div v-if="scope.row.type==6">百分比</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit_quota(scope.row)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;编辑
                            </el-button>
                            <el-button type="text" size="small" @click="del_quota(scope.row)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <AddAbility v-if="popTitle=='添加工艺能力' || popTitle=='编辑工艺能力'" :item="abilityItem" @hide="hide"></AddAbility>
            <EditCraft v-else :item="item" :popTitle="popTitle" @hide="hide"></EditCraft>
        </el-dialog>
    </div>
</template>
<script>
    import { listAbility,delAbility,listQuota,delQuota } from '@/service/dictionary';
    import EditCraft from './EditCraft.vue';
    import AddAbility from './AddAbility.vue';
    export default {
        components: {
            EditCraft,AddAbility
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                abilityList:[],
                abilityIndex:0,
                quotaList:[],
                quotaId:"",
                item:{
                    ability_id:"",
                    ability_name:"",
                    name:"",
                    type:"",
                    options: [],
                },
                abilityItem:{},

            }
        },
        created() {
            this.ability_list();
        },
        mounted() {},
        methods: {
            tab(item,index) {
                this.abilityIndex = index;
                this.quotaId = item.id;
                this.quota_list(this.quotaId);
            },
            ability_list() {
                console.log('listAbility');
                listAbility().then(rst => {
                    this.abilityList = rst;
                    this.quotaId = rst[this.abilityIndex].id;
                    this.quota_list(this.quotaId);
                    console.log(this.abilityList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add_ability() {
                this.popTitle = "添加工艺能力";
                this.popVisible = true;
            },
            edit_ability() {
                this.popTitle = "编辑工艺能力";
                this.popVisible = true;
                this.abilityItem = this.abilityList[this.abilityIndex];
                console.log(this.abilityItem);
            },
            del_ability(item) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delAbility({id:item.id}).then(rst => {
                        this.ability_list();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
            hide() {
                this.popVisible=false;
                this.ability_list();
            },
            quota_list(id) {
                console.log('listQuota');
                listQuota({ability_id:id}).then(rst => {
                    this.quotaList = rst;
                    console.log(this.quotaList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add_quota() {
                this.item = {
                    ability_id:this.abilityList[this.abilityIndex].id,
                    ability_name:this.abilityList[this.abilityIndex].name,
                    name:"",
                    type:"",
                    options: [],
                }
                this.popTitle = "新增工艺能力指标";
                this.popVisible = true;
            },
            edit_quota(item,index) {
                this.item = {
                    id:item.id,
                    ability_name:this.abilityList[this.abilityIndex].name,
                    name:item.name,
                    type:item.type,
                    options:item.options,
                }
                this.popTitle = "修改工艺能力指标";
                this.popVisible = true;
            },
            del_quota(item) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delQuota({id:item.id}).then(rst => {
                        this.quota_list(this.quotaId);
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
    .select_list>div {
        padding:0 15px;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        border: 1px solid rgb(64, 158, 255);
        color: rgb(64, 158, 255);
        border-radius: 4px;
        margin-top: 3px;
        margin-right:15px;
    }
    .select_list>div.active {
        background: rgb(64, 158, 255);
        color: #fff;
    }
    .select_list>div.add_item {
        border: 1px dashed #999;
        color: #777;
    }
    .select_item {
        position: relative;
    }
    .select_item i {
        position: absolute;
        top: -10px;
        right: -9px;
        font-size: 14px;
        color: #fff;
        display: inline-block;
        vertical-align: top;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.4);
    }
</style>