import { render, staticRenderFns } from "./AddAbility.vue?vue&type=template&id=1334a7d0&scoped=true&"
import script from "./AddAbility.vue?vue&type=script&lang=js&"
export * from "./AddAbility.vue?vue&type=script&lang=js&"
import style0 from "./AddAbility.vue?vue&type=style&index=0&id=1334a7d0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1334a7d0",
  null
  
)

export default component.exports